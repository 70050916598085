@import "./base";

body {
	@extend %base;

	.store-button {
		padding: 6px 32px;
		border-radius: 30px;
		border: solid 1px $purple;
		font-size: 17px;
		color: $purple;
		margin: 10px 20px;
		background-color: white;

		display: flex;
		flex-direction: row;
		align-items: center;

		.text {
			margin-left: 15px;
		}
	}
	.store-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-flow: wrap;

		margin-top: 72px;
		@media screen and (max-width: $break-phone) {
			margin-top: 42px;
		}
	}
	.first-box {
		.first-bg {
			background-image: url("../img/bg/photo-1536009744269-d24508b32196-1000px.jpg");
			background-position-y: 0%;
			opacity: 0.6;
		}
	}
	section {
		&.internet {
			padding-top: 60px;
			@media screen and (max-width: $break-phone) {
				padding-top: 20px;
			}
			.screens {
				display: flex;
				justify-content: center;
				align-items: flex-end;

				margin-top: 100px;
				@media screen and (max-width: $break-phone) {
					margin-top: 75px;
				}
				.main {
					width: 255px;
					height: 405px;
					z-index: 10;
				}
				.left,
				.right {
					width: 238px;
					height: 285px;
					position: relative;
					@media screen and (max-width: $break-phone) {
						display: none;
					}
				}
				.left {
					left: 25.5px;
				}
				.right {
					right: 25.5px;
				}
			}
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 0.04)
			);
			background-position: center bottom;
			background-size: 100% 250px;
			background-repeat: no-repeat;
		}
		&.partners {
			padding: 24px 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			@media screen and (max-width: $break-phone) {
				flex-direction: column;
			}
			.partner {
				margin: 10px 40px;
				opacity: 0.55;
				@media screen and (max-width: $break-phone) {
					margin: 25px 0;
				}
			}
		}
		&.plans {
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 0.04)
			);
			background-position: center top;
			background-size: 100% 250px;
			background-repeat: no-repeat;

			.screens {
				align-self: stretch;

				display: flex;
				justify-content: space-between;
				align-items: center;

				margin: 0 42px;
				margin-top: 100px;
				@media screen and (max-width: $break-phone) {
					margin: 60px 0 0;
					justify-content: center;
				}
				.side {
					width: 230px;
					height: 408px;
					opacity: 0.5;
					@media screen and (max-width: $break-tablet) {
						display: none;
					}
				}
				.center {
					width: 258px;
					height: 458px;
					opacity: 0.8;
					@media screen and (max-width: $break-phone) {
						display: none;
					}
				}
				.main {
					width: 280px;
					max-width: 100%;
				}
			}
		}
		&.matching {
			.graph {
				width: 1032px;
				max-width: 85%;
				margin-top: 160px;
				@media screen and (max-width: $break-tablet) {
					margin-top: 80px;
				}
				@media screen and (max-width: $break-phone) {
					margin-top: 62px;
				}
			}
		}
		&.privacy {
			.images {
				margin-top: 100px;
				align-self: stretch;

				display: flex;
				justify-content: space-evenly;
				@media screen and (max-width: $break-tablet) {
					flex-direction: column;
					align-items: center;
					margin-top: 50px;
				}
				@media screen and (max-width: $break-phone) {
					margin-top: 24px;
				}
				.image {
					display: flex;
					flex-direction: column;
					align-items: center;
					max-width: 500px;
					@media screen and (max-width: $break-tablet) {
						margin: 30px 0;
					}
					img {
						margin-bottom: 40px;
						max-width: 100%;
					}
					@media screen and (max-width: $break-phone) {
						.social {
							height: auto;
						}
					}
					.settings {
						border-radius: 4px;
					}
					.title {
						max-width: 392px;
						font-size: 20px;
					}
					.subtitle {
						font-size: 18px;
					}
				}
			}
		}
		&.hero {
			padding-bottom: 160px;
			.store-buttons {
				margin-top: 60px;
			}
		}
	}
}
