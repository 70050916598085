@import "../../vendor/meyer-reset/2.0/reset.min.css";
@import "./font";
@import "./variables";

%base {
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: stretch;

	font-family: "Maven Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
		sans-serif;
	font-weight: 500;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	a {
		text-decoration: none;
		color: inherit;
	}
	.horiz-center-crop {
		display: flex;
		flex-direction: row;
		justify-content: center;
		overflow: hidden;
		max-width: 100%;
		& > * {
			flex: 1 0 auto;
		}
	}
	.first-box {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		position: relative;
		.first-bg {
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			position: absolute;
			z-index: -1;

			opacity: 0.3;
			background-position-x: center;
			background-position-y: bottom;
			background-size: cover;
		}
		.first-content {
			z-index: 0;
		}
	}
	header {
		display: flex;
		justify-content: space-between;
		padding: 60px 100px;

		@media screen and (max-width: $break-phone) {
			flex-direction: column;
			align-items: center;
			padding: 0 0 40px;
		}
		.navlist {
			display: flex;
			align-items: center;
			text-align: center;
			@media screen and (max-width: $break-phone) {
				order: -1;
				align-self: stretch;
				margin-bottom: 40px;
			}
			a {
				padding: 32px 17px;

				font-size: 18px;
				color: $purple;

				@media screen and (max-width: $break-phone) {
					padding: 14px 16px;
					flex-grow: 1;
					background-color: #615acb;
					color: #f2f2f2;
					&.active, &:hover, &:active {
						background-color: transparent;
						color: #615acb;
					}
				}
			}
		}
	}
	section {
		display: flex;
		flex-direction: column;
		align-items: center;

		padding-top: 160px;
		@media screen and (max-width: $break-tablet) {
			padding-top: 80px;
		}
		.title {
			font-size: 34px;
			color: $purple;
			margin: 0 32px 32px;
			text-align: center;
		}
		.subtitle {
			max-width: 900px;

			text-align: center;
			font-size: 20px;
			color: $dark-black;
			margin: 0 32px;
		}
	}
	article section {
		padding: 20px 34px 160px;
		margin: 0 auto;
		max-width: 65em;
		color: $dark-black;
		font-size: 20px;
		text-align: left;
		display: block;

		h1,
		h2,
		h3,
		h4 {
			color: $purple;
			margin: 1.3em 0.5em 0.6em;
			text-align: center;
		}
		h1,
		h2 {
			font-size: 34px;
		}
		h3 {
			font-size: 30px;
		}
		ul {
			list-style-type: square;
		}
		ol {
			list-style-type: decimal;
		}
		ul,
		ol {
			margin-left: 2em;
		}
		p,
		li {
			margin-top: 0.9em;
		}
	}
	footer {
		padding: 70px 40px 100px;
		background-color: $dark-black;
		color: white;
		font-size: 14px;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;

		@media screen and (max-width: $break-tablet) {
			flex-direction: column;
			align-items: center;
			padding-bottom: 70px;
		}
		.copyright {
			font-size: 12px;
			margin-top: 40px;
			@media screen and (max-width: $break-phone) {
				margin-top: 30px;
			}
		}

		.links {
			margin-left: 23px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			@media screen and (max-width: $break-tablet) {
				flex-direction: column;
				align-items: center;
				margin: 25px 0
			}
		}

		.links > div {
			margin: 0 40px;
			@media screen and (max-width: $break-tablet) {
				margin: 20px 0;
				text-align: center;
			}
		}

		h4 {
			text-transform: uppercase;
			margin-bottom: 24px;
		}

		ul {
			margin: -8px 0;
			display: flex;
			flex-direction: column;
			li {
				margin: 8px 0;
			}
		}

		.social a {
			display: flex;
			flex-direction: row;
			align-items: middle;
			justify-content: flex-start;
			.icon {
				margin: -3px 8px -3px 0;
			}
		}
	}
}
